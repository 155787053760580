
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

function ConfirmOrders() {
    const [date, setDate] = useState(getTodayDate());
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [lunch, setLunch] = useState(false);
    const [dinner, setDinner] = useState(false);

    useEffect(() => {
        getOrderList();
    }, []);

    const getOrderList = async () => {
        const token = localStorage.getItem("token")
        const dateValue = getTodayDate();

        try {
            const res = await axios.get("https://dlunchbox.com/dateWiseConfirmOrderList", {
                params: { dateValue },
                headers: {
                    Authorization: token,
                },
            });
            setData(res.data);
            setFilteredData(res.data);
        } catch (err) {
            console.log(err);
        }
    };

    function getTodayDate() {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        let day = today.getDate();

        // Adding leading zeros if month or day are less than 10
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;

        return `${year}-${month}-${day}`;
    }

    function handleDateChange(event) {
        setDate(event.target.value);
        getDateWiseList(event.target.value);
    }

    const getDateWiseList = async (dateValue) => {
        const token = localStorage.getItem("token")
        try {
            const res = await axios.get("https://dlunchbox.com/dateWiseConfirmOrderList", {
                params: { dateValue },
                headers: {
                    Authorization: token,
                },
            });
            setData(res.data);
            setFilteredData(res.data);
        } catch (err) {
            console.log(err);
        }
    };

    const lunchHnd = () => {
        setLunch(true);
        setDinner(false);
    };

    const dinnerHnd = () => {
        setLunch(false);
        setDinner(true);
    };

    const handleFilter = (e) => {
        const keyword = e.target.value.toLowerCase();
        const filtered = data.filter(item =>
            item.name.toLowerCase().includes(keyword) ||
            item.deliveryaddress.toLowerCase().includes(keyword) ||
            item.city.toLowerCase().includes(keyword) ||
            item.landmark.toLowerCase().includes(keyword) ||
            item.deliverytime.toLowerCase().includes(keyword) ||
            item.rice.toLowerCase().includes(keyword) ||
            item.vegetable1.toLowerCase().includes(keyword) ||
            item.vegetable2.toLowerCase().includes(keyword) ||
            item.bread.toLowerCase().includes(keyword) ||
            item.sweet.toLowerCase().includes(keyword)
        );
        setFilteredData(filtered);
    };

    const exportToXLS = () => {
        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'ConfirmedOrders');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xls', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.ms-excel;charset=UTF-8' });
        saveAs(dataBlob, 'ConfirmedOrders.xls');
    };

    const Lunch = filteredData.filter(order => !order.deliverytime.includes('7'));
    const Dinner = filteredData.filter(order => order.deliverytime.includes('7'));

    return (
        <div>
            <div className=''>
                <div className='flex flex-row justify-center gap-12 py-4 bg-blue-200'>
                    <label>Select A Date:</label>
                    <input onChange={handleDateChange} id="date" type='date' name="date" value={date}
                        className='border border-solid border-blue-800 w-40 text-center'></input>
                </div>
            </div>

            <p className='text-center text-3xl mb-4 font-medium'>Confirmed Orders</p>
            <div className='flex flex-row justify-center gap-8 mb-4'>
                <button onClick={lunchHnd} className={`px-4 py-2 rounded ${lunch ? 'bg-green-500 text-white' : 'bg-gray-400 text-black'
                    }`}>Lunch</button>
                <button onClick={dinnerHnd} className={`px-4 py-2 rounded ${dinner ? 'bg-green-500 text-white' : 'bg-gray-400 text-black'
                    }`}>Dinner</button>
            </div>

            <div>
                {/* <input type="text" placeholder="Search..." onChange={handleFilter} /> */}
                <button onClick={exportToXLS}>Export to XLS</button>
            </div>


            <div>
                {lunch && <p>Lunch Counter: {Lunch.length}</p>}
                {dinner && <p>Dinner Counter: {Dinner.length}</p>}
            </div>

            {lunch && <div>
                {Lunch.map((order, index) => (
                    <div key={index} className='flex flex-row gap-4 flex-wrap ml-4 border border-solid border-gray-300'>
                        <p>No: {order.id}</p>
                        <p>Name: {order.name}</p>
                        <p>Mobile: {order.mobno}</p>
                        <p>Address: {order.deliveryaddress}</p>
                        <p>City: {order.city}</p>
                        <p>Landmark: {order.landmark}</p>
                        <p>Pincode: {order.pincode}</p>
                        <p>Delivery Time: {order.deliverytime}</p>
                        {order.current_location.length > 1 && <p>Current Location: {order.current_location}</p>}
                        <p>Box: {order.rice}, {order.vegetable1}, {order.vegetable2}, {order.bread}, {order.sweet}</p>
                        <p><b>Quantity: </b>{order.quantities}</p>
                    </div>
                ))}
            </div>}

            {dinner && <div>
                {Dinner.map((order, index) => (
                    <div key={index} className='flex flex-row gap-4 flex-wrap ml-4 border border-solid border-gray-300'>
                        <p>No: {order.id}</p>
                        <p>Name: {order.name}</p>
                        <p>Mobile: {order.mobno}</p>
                        <p>Address: {order.deliveryaddress}</p>
                        <p>City: {order.city}</p>
                        <p>Landmark: {order.landmark}</p>
                        <p>Pincode: {order.pincode}</p>
                        <p>Delivery Time: {order.deliverytime}</p>
                        {order.current_location.length > 1 && <p>Current Location: {order.current_location}</p>}
                        <p>Box: {order.rice}, {order.vegetable1}, {order.vegetable2}, {order.bread}, {order.sweet}</p>
                        <p><b>Quantity: </b>{order.quantities}</p>

                    </div>
                ))}
            </div>}
        </div>
    );
}

export default ConfirmOrders;

